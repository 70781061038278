@import '../../../global.scss';
@import '~bootstrap/scss/bootstrap.scss';

.splash-container {
    position:relative;
    top:0;
    left:0;
    width: 100%;
    height:80vh;
    z-index: 0;

    .svg-container {
        position:absolute;
        width: 100vw;
        height: 100%;
    }

    .overlow-control-container {
        position:relative;
        width: 100%;
        height:150%;
        overflow: hidden;
    }

    .purple-overlow-control-container {
        position:absolute;
        top:-100px;
        width: 100%;
        height:160%;
        overflow: hidden;
    }

    .intro{
        text-align: left;
        position: relative;
        z-index: 10;
        color: $textColor;
        top:-240px;
        font-family: "PTSans-Regular";

        h1 {
            font-size: $titleSize;
            margin-bottom: 18px;
        }
        
        p {
            font-size: $textSize;
        }
    }

    .absolute-elem {
        position:absolute;
        z-index: 1;
    }

    .purple-blob {
        width: 50%;
        top:0%;
        left:3%;
    }

    .orange-blob {
        width:20%;
        left:-9%;
        top:40%;
    }

    .blue-blob {
        width: 20%;
        right:-8%;
        top:20%;
    }

    .green-blob {
        width:40%;
        bottom:-25%;
        left:30%;
    }

    .white-blob {
        width:80%;
        bottom:10%;
        right:-20%;
    }

    .letter-r-one {
        width:27%;
        bottom:17%;
        left:29%;
    }
    
    .letter-e {
        width: 15%;
        bottom:5%;
        left:41%;
        z-index:2;
    }

    .letter-s {
        width: 15%;
        bottom:-5%;
        left:50%;
    }

    .letter-t-one {
        width: 15%;
        bottom:15%;
        left:52%;
    }

    .letter-a {
        width: 15%;
        bottom:8%;
        left:63%;
        z-index:2;
    }

    .letter-r-two {
        width: 15%;
        bottom:16%;
        left:72%;
    }

    .letter-t-two {
        width: 15%;
        bottom:10%;
        left:81%;
        z-index:1;
    }

    .floating-blob {
        width:70%;
        bottom:20%;
        left:10%;
    }

    .key-p {
        width:10%;
        left:0;
        bottom:-80%
    }

    .key-square {
        width:10%;
        left:10%;
        bottom:-60%
    }

    .key-arrow {
        width:10%;
        left:20%;
        bottom:-76%
    }

    .key-eight {
        width:10%;
        left:35%;
        bottom:-83%
    }

    .purpose-code {
        position:absolute;
        width:40%;
        height: auto;
        left:0;
        bottom:-800px;
    }

    .purple-radial{
        position:absolute;
        background: radial-gradient($purpleRadialColor , transparent, transparent);
        z-index:0;

        &.radial-one {
            top:0;
            left:0;
            width: 80vw;
            height:80vw;
            margin-left: -40vw;
            margin-top: -30vw;
        }

        &.radial-two {
            top:0;
            right:0;
            width: 60vw;
            height:60vw;
            margin-right: -30vw;
            margin-top: -30vw;
        }

        &.radial-three {
            bottom:20%;
            right:0;
            width: 60vw;
            height: 60vw;
            margin-right: -10vw;
            margin-bottom: -50vw;
        }
    }
}

@include media-breakpoint-up(lg) {
    .splash-container {
        max-height: 600px;

        .svg-container {
            top:-60%;
        }

        .intro {
            left:10%;
        }
    }
}

@include media-breakpoint-down(lg) {
    .splash-container {
        max-height: 540px;

        .svg-container {
            top:-60%;
        }

        .purple-overlow-control-container {
            height:160%;
        }

        .intro{
            left:24%;
            width:50%;
        }  

        .floating-blob {
            bottom:20%;
        }

        .key-p {
            bottom:-100%
        }
    
        .key-square {
            bottom:-80%
        }
    
        .key-arrow {
            bottom:-96%
        }
    
        .key-eight {
            bottom:-103%
        }
    }
}

@include media-breakpoint-down(md) {
    .splash-container {
        max-height: 260px;
        .svg-container {
            top:-110%;
        }

        .purple-overlow-control-container {
            height:250%;
        }

        .intro{
            position: relative;
            top:0px;
            left:10vw;
            width: 80vw;
            justify-content: center;    
        }

        .letter-r-one {
            width:22%;
            bottom:10%;
        }
        
        .letter-e {
            width: 10%;
        }
    
        .letter-s {
            width: 10%;
        }
    
        .letter-t-one {
            width: 10%;
        }
    
        .letter-a {
            width: 10%;
        }
    
        .letter-r-two {
            width: 10%;
        }
    
        .letter-t-two {
            width: 10%;
        }  

        .key-p {
            bottom:-140%
        }
    
        .key-square {
            bottom:-120%
        }
    
        .key-arrow {
            bottom:-136%
        }
    
        .key-eight {
            bottom:-143%
        }
    }
}

@include media-breakpoint-down(md) {
    .splash-container {
        .intro{
            left:40px;   
        }
    }
}