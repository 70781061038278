@import '../../../global.scss';
@import '~bootstrap/scss/bootstrap.scss';

.story-section {
    background-color: $storySectionColor;
    width: 100vw;
    position: relative;
    text-align: left;
    top:130px;

    .content {
        position:relative;
        font-size:$textSize;
        color:$textColor;
        padding:40px 40px;
        z-index: 1;

        h2 {
            font-family: "PTSans-Bold";
            font-size:$titleSize;
        }
    }
}