.App {
    text-align: center;
    background-color: black;
    font-family:'PTSans-Regular', -apple-system, BlinkMacSystemFont,
    sans-serif;
}

.App-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
}

.App-content {
    position: relative;
    top:0;
    margin:0;
    padding:0;
}
