@import '../../../global.scss';
@import '~bootstrap/scss/bootstrap.scss';

.design-section {
    background-color: transparent;
    width: 100vw;
    position: relative;
    text-align: left;
    overflow:hidden;

    .content {
        position:relative;
        font-size:$textSize;
        color:$textColor;
        padding:40px 40px;
        z-index: 1;

        h2 {
            font-family: "PTSans-Bold";
            font-size:$titleSize;
        }

        p {
            margin-bottom:0;
        }
        
        .design-sub-section {
            margin:60px 0;
            .sub-title {
                margin:20px 0;
            }

            .logo-container {
                text-align: center;
                height:120px;
            }

            .identity-logo {
                max-width:100%;
                max-height:100%;
                vertical-align: middle;
            }

            .cover {
                width:360px;
            }
        }
    }

    .purple-radial{
        position:absolute;
        background: radial-gradient($purpleRadialColor , transparent, transparent);
        z-index:0;
        right:-30%;
        top:0;
        width:70vw;
        height:70vw;
    }
}

@include media-breakpoint-down(md) {
    .design-section {
        .content {
            padding:40px 30px;

            .design-sub-section {
                margin:30px 0;
                .sub-title {
                    margin:20px 0;
                }
                
                .logo-container {
                    margin:10px 0;
                }
    
                .cover {
                    width:300px;
                }

                .cover-container {
                    text-align: center;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .design-section {
        .content {
            padding:40px 40px;

            .design-sub-section {
                margin:20px 0;
                .sub-title {
                    margin:20px 0;
                }
                
                .identity-logo {
                    height:100px;
                }
    
                .cover {
                    width:300px;
                }

                
            }
        }
    }
}