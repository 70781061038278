@font-face {
    font-family: 'PTSans-Regular';
        src: url('./PTSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PTSans-Bold';
        src: url('./PTSans-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}