@import '../../global.scss';

.Nav-container {
    width: 140px;
    min-height: 300px;
    position: -webkit-sticky;
    position: sticky;
    margin-left: 0px;
    top: 0;
    z-index:2;

    .logo-container {
        background-color: #4D4D4D;
        width: 100%;
        height:100%;
    }
    
    .App-logo {
        margin: 10px 8px 0 8px;
        pointer-events: none;
    }

    nav {
      min-height:200px;
      font-size: 18px;
    }
    .Expand-collapse-btn {
        display: none;
    }

    .Nav-menu {
        background-color: #4D4D4D;
        list-style: none;
        display: inline-block;
        padding:15px 0px;
        margin:0px;
        width:100%;
        font-size: $textSize;

        li a{
            color:white;
            text-decoration: none;
        }
    }

    hr {
        margin:0 10px;
        padding: 0;
        color:#fff;
    }

    @media (max-width: 768px) {
        width: 100px;
        min-height: 100px;
  
        nav {
            height: auto;
        }

        .Expand-collapse-btn {
            display: inline-block;
            width:100%;
            background-color: #4D4D4D;
            border:0;
            margin:0;
            padding: 10px 0px;
        }

        .arrow {
            border: solid white;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 3px;
        }

        .up {
            transform: rotate(-135deg);
            -webkit-transform: rotate(-135deg);
          }
          
          .down {
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
          }

        .Nav-menu {
            list-style: none;
            overflow: hidden;
            
            &.expanded {
                margin-bottom:10px;
                animation: fadeIn 0.15s ease-out;
                padding:0;
                padding-bottom:15px;
            }
            &.collapsed {
                max-height: 0px;
                animation: fadeOut 0.15s ease-out;
                padding:0px;
            }
        }
    }
    // @media (prefers-reduced-motion: no-preference) {
    //   .App-logo {
    //     animation: App-logo-float infinite 3s ease-in-out;
    //   }
    // }
  }

    @keyframes fadeIn {
        from {  max-height: 0;}
        to {  max-height:300px; }
    }

    @keyframes fadeOut {
        from { max-height: 300px; }
        to { max-height: 0;}
    }

    @keyframes App-logo-float {
        0% {
        transform: translateY(0);
        }
        50% {
        transform: translateY(5px);
        }
        100% {
        transform: translateY(0px);
        }
    }
  