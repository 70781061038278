@import './reset.scss';
@import './global.scss';
@import './fonts/fonts.scss';
@import '~bootstrap/scss/bootstrap.scss';

body {
  margin: 0;
  font-family:'PTSans-Regular', -apple-system, BlinkMacSystemFont,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
