@import '../../../global.scss';
@import '~bootstrap/scss/bootstrap.scss';

$paragraphSpacing: 40px;

.code-section {
    background-color: $purpleSectionColor;
    width: 100vw;
    position: relative;
    text-align: left;
    top:-100px;

    .content {
        position:relative;
        font-size:$textSize;
        color:$textColor;
        padding:40px 40px;
        z-index: 1;

        h2 {
            font-family: "PTSans-Bold";
            font-size:$titleSize;
        }

        p {
            margin-bottom:0;
        }

        .clients, .agencies{
            img {
                padding-right:20px;
                padding-bottom:20px;
                width:auto;
                height:70px;
            }
            p {
                display: inline;
            }
            margin:20px 0 $paragraphSpacing 0;
        }

        .appIcons{
            margin-bottom: $paragraphSpacing;
            img {
                width:60px;
            }

            p {
                min-height:60px;
                padding:10px 0;
            }

            a {
                color: white;
                text-decoration: none;
                img {
                    transform: translateY(0) scale(1);
                    transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
                }
                &:hover {
                    img {
                        transform: translateY(0) scale(1.1);
                    }
                }
                
            }
        }
    }

    .purple-curve {
        position:absolute;
        right:0;
        bottom:0;
        z-index:0;
    }
    
    .social-btn {
        background-color: transparent;
        
        img {
            margin:20px 20px 20px 0px;
            width:40px;
            filter: invert(82%) sepia(51%) saturate(1747%) hue-rotate(195deg) brightness(104%) contrast(104%);
            transform: translateY(0) scale(1);
            transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
            &:hover {
                filter: invert(100%) sepia(4%) saturate(2%) hue-rotate(80deg) brightness(109%) contrast(101%);
                transform: translateY(0) scale(1.2);
            }
        }
    }
}


@include media-breakpoint-down(lg) {
    .code-section {
        .content {
            .clients, .agencies{
                img {
                    height:60px;
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .code-section {
        .content {
            padding:40px 30px;
            .clients, .agencies{
                img {
                    height:55px;
                    padding-right:10px;
                }
                p {
                    display:inline;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .code-section {
        top:0px;
        .content {
            padding:40px 40px;
        }
    }
}